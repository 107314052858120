import apiClient from '../api/base';
import { AxiosError } from 'axios';
import { useState } from 'react';

const useDeleteUser = () => {
  const [errorMessage, setError] = useState('');
  const deleteUserWithId = async (userId: number): Promise<number> => {
    setError('');
    try {
      await apiClient.delete('users/', { data: { id: userId } });
      return 204;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        if (e.response.status === 403) {
          setError(
            'Nie masz wystarczających uprawnień do usuwania użytkowników'
          );
        } else {
          setError('Wystąpił błąd podczas usuwania użytkownika');
        }

        return e.response.status;
      } else {
        return 500;
      }
    }
  };

  return {
    deleteUserWithId,
    errorMessage,
    setError,
  };
};

export default useDeleteUser;
