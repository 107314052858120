import Chart from "react-apexcharts";

interface PreviewChartProps {
  activeTab: string;
}

export default function PreviewChart({ activeTab }: PreviewChartProps) {
  const dataValue =
    activeTab === "tabRecent" ? [10, 5, 15, 25, 27.5] : [15, 10, 20, 30, 100.5];

  const chartOptions = {
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      background: "transparent",
      menu: false,
      foreColor: "#333",
      sparkline: {
        enabled: true,
      },
    },
    series: [
      {
        data: dataValue,
      },
    ],
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: -15,
      },
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
  };

  return (
    <Chart
      options={chartOptions}
      series={chartOptions.series}
      type="line"
      width="100%"
      height="25%"
    />
  );
}
