import React from 'react';

interface NotificationItemProps {
  created_at: string;
  user_fullname: string;
  notification_id: number;
  notification_type: string;
  icon: React.ReactNode;
  colors: string;
}

export default function NotificationItem({
  created_at,
  user_fullname,
  notification_id,
  notification_type,
  icon,
  colors,
}: NotificationItemProps) {
  const mapNotificationType = (type: string) => {
    if (type === 'new_file') {
      return 'Zarejestrował nowy plik .DDD';
    } else if (type === 'new_report') {
      return 'Zarejestrował nowy raport z tachografu';
    }
  };

  const mapDate = (dateString: string) => {
    const date = new Date(dateString);
    const formatedDate = date.toLocaleDateString('pl-PL');
    const formatedTime = date.toLocaleTimeString('pl-PL');

    return formatedDate + ' ' + formatedTime;
  };

  return (
    <>
      <div className="flex items-center space-x-3">
        <div
          className={`flex size-10 shrink-0 items-center justify-center rounded-lg ${colors}`}>
          {icon}
        </div>
        <div>
          <p className="font-medium text-slate-600 dark:text-navy-100">
            {user_fullname} {mapDate(created_at)}
          </p>
          <div className="mt-1 text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
            {mapNotificationType(notification_type)}
          </div>
        </div>
      </div>
    </>
  );
}
