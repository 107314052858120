import React from 'react';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';

interface IPagination {
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  prvPage: string | null;
  nextPage: string | null;
  currentPage: number;
}

const Pagination: React.FC<IPagination> = ({
  currentPage,
  goToNextPage,
  prvPage,
  nextPage,
  goToPreviousPage,
}) => {
  return (
    <div className="flex flex-row gap-1 justify-center items-center">
      <button
        disabled={!prvPage}
        onClick={goToPreviousPage}
        className="btn py-2 px-0 text-3xl text-primary bg-transparent hover:text-primary-focus focus:text-primary-focus active:text-primary-focus/90 dark:text-accent dark:hover:text-accent-focus dark:focus:text-accent-focus dark:active:text-accent/90 disabled:text-transparent">
        <FaCaretLeft />
      </button>
      <p className="text-2xl rounded-md">{currentPage}</p>
      <button
        disabled={!nextPage}
        onClick={goToNextPage}
        className="btn py-2 px-0 text-3xl text-primary bg-transparent hover:text-primary-focus focus:text-primary-focus active:text-primary-focus/90 dark:text-accent dark:hover:text-accent-focus dark:focus:text-accent-focus dark:active:text-accent/90 disabled:text-gray-400">
        <FaCaretRight />
      </button>
    </div>
  );
};

export default Pagination;
