import React from "react";

interface DeliveryCardStatusProps {
  avatarImg: string;
  boderColorClass: string;
  from: string;
  to: string;
  driverName: string;
  date: string;
  place: string;
}

export default function DeliveryCardStatus({
  avatarImg,
  boderColorClass: borderColor,
  from,
  to,
  driverName,
  date,
  place,
}: DeliveryCardStatusProps) {
  return (
    <>
      <div
        className={`flex flex-col justify-between border-4 border-transparent ${borderColor} px-4`}
      >
        <div>
          <p className="text-base font-medium text-slate-600 dark:text-navy-100">
            {driverName}
          </p>
          <p className="text-xs text-slate-400 dark:text-navy-300">Kierowca</p>
          <div className="badge mt-2 bg-info/10 text-info dark:bg-info/15">
            {from}
          </div>
          <div className="badge mt-2 bg-secondary/10 text-secondary dark:bg-secondary-light/15 dark:text-secondary-light">
            {to}
          </div>
        </div>
        <div>
          <div className="mt-8">
            <p className="font-inter">
              <span className="text-2xl font-medium text-slate-600 dark:text-navy-100">
                {place}
              </span>
              <br></br>
              <span className="text-xs">Ostatnio zarejestrowane miejsce</span>
            </p>
            <p className="mt-1 text-xs">{date}</p>
          </div>
          <div className="mt-8 flex items-center justify-between space-x-2">
            <div className="flex -space-x-3">
              <div className="avatar size-8 hover:z-10">
                <img
                  className="rounded-full ring ring-white dark:ring-navy-700"
                  src={avatarImg}
                  alt="avatar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
