import React from 'react';

// #TODO - Work on it
export default function ProgressBar() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-2/3 md:w-1/2 lg:w-1/3">
        <div className="progress h-4 bg-slate-150 dark:bg-navy-500">
          <div className="is-indeterminate relative w-full rounded-full bg-slate-500 dark:bg-navy-400"></div>
        </div>
      </div>
    </div>
  );
}
