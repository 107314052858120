import React, { useState, useEffect, useRef } from 'react';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { Notifications } from '..';
import { useContext } from 'react';
import { NotificationContext } from '../../../context/NotificationContext/NotificationContext';

interface NavbarProps {
  isSidebarExpanded: boolean;
  toggleSidebar: () => void;
}

const Navbar = ({ isSidebarExpanded, toggleSidebar }: NavbarProps) => {
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const navbarRef = useRef<HTMLButtonElement>(null);
  const notificationsRef = useRef<HTMLDivElement>(null);
  const notificationContext = useContext(NotificationContext);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        showNotifications &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node) &&
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target as Node)
      ) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [showNotifications]);

  const renderAlert = () => {
    if (
      notificationContext?.notifications &&
      notificationContext.notifications.length > 0
    ) {
      return (
        <span className="absolute -top-px -right-px flex size-3 items-center justify-center">
          <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-secondary opacity-80"></span>
          <span className="inline-flex size-2 rounded-full bg-secondary"></span>
        </span>
      );
    }
  };

  return (
    <nav className="header before:bg-white dark:before:bg-navy-750 print:hidden">
      <div className="header-container relative flex w-full bg-white dark:bg-navy-750 print:hidden">
        <div className="flex w-full items-center justify-between">
          {/* Hamburger */}
          <div className="h-7 w-7">
            <button
              className={`menu-toggle ml-0.5 flex h-7 w-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80 ${
                isSidebarExpanded ? 'active' : ''
              }`}
              onClick={toggleSidebar}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>

          {/* Small icons */}
          <div className="-mr-15 flex items-center space-x-2">
            {/* Notifications */}
            <div className="flex">
              <button
                className="btn relative size-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                onClick={toggleNotifications}
                ref={navbarRef}>
                <IoIosNotificationsOutline className="size-6 text-slate-500 dark:text-navy-100" />
                {renderAlert()}
              </button>

              {/* Toggler for notifications */}
              {showNotifications ? (
                <Notifications isOpen={true} ref={notificationsRef} />
              ) : (
                <Notifications isOpen={false} ref={notificationsRef} />
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
