import React, {
  useState,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';
import apiClient from '../api/base';

interface ProvideAuthProps {
  children: React.ReactNode;
}

interface UseProvideAuthContext {
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  logIn: (username: string, password: string) => Promise<boolean>;
  logOut: () => Promise<boolean>;
  isLogged: () => Promise<boolean>;
  isAdmin: () => Promise<boolean>;
  refreshAuth: () => Promise<boolean>;
}

const authContext = createContext<UseProvideAuthContext | null>(null);

export default () => {
  return useContext(authContext);
};

export function ProvideAuth({ children }: ProvideAuthProps) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useProvideAuth() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasLogged, setHasLogged] = useState<boolean>(false);

  const logIn = async (
    username: string,
    password: string
  ): Promise<boolean> => {
    setErrorMessage('');
    try {
      await apiClient.post(
        'auth/login/',
        {
          username: username,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setErrorMessage('');
      setHasLogged(true);

      return true;
    } catch (e: any) {
      const status = e.response?.status;
      if (status === 401 || status === 400 || status === 403) {
        setErrorMessage(
          'Nie znaleziono użytkownika z takimi danymi logowania.'
        );
      } else {
        setErrorMessage(
          'Wystąpił błąd serwera. Skontaktuj się z administratorem.'
        );
      }

      return false;
    }
  };

  const logOut = async (): Promise<boolean> => {
    try {
      await apiClient.post('auth/logout/');

      setErrorMessage('');
      setHasLogged(false);

      return true;
    } catch (e: any) {
      const statusCode = e.response.status;
      if (statusCode !== 401) {
        setErrorMessage('Wystąpił błąd podczas wylogowywania.');
        return false;
      } else {
        return true;
      }
    }
  };

  const checkSession = async (): Promise<boolean> => {
    try {
      await apiClient.post('auth/verify/');
      setErrorMessage('');
      return true;
    } catch (e: any) {
      const status = e.response?.status;
      if (status === 401 || status === 400) {
        setErrorMessage('Twoja sesja wygasła. Zaloguj się jeszcze raz.');
      } else {
        setErrorMessage(
          'Wystąpił błąd serwera. Skontaktuj się z administratorem.'
        );
      }
      return false;
    }
  };

  const isLogged = async (): Promise<boolean> => {
    return await checkSession();
  };

  const isAdmin = async (): Promise<boolean> => {
    try {
      const response = await apiClient.get('auth/is-admin/');
      if (response.data === false) {
        setErrorMessage(
          'Nie znaleziono administratora z takimi danymi logowania'
        );
      }
      return response.data;
    } catch (e: any) {
      return false;
    }
  };

  const refreshAuth = async (): Promise<boolean> => {
    try {
      await apiClient.post('auth/refresh/');
      return true;
    } catch (e: any) {
      if (hasLogged) {
        alert('Twoja sesła wygasła. Zaloguj się jeszcze raz.');
        setHasLogged(false);
        window.location.href = '/';
      }

      return false;
    }
  };

  return {
    errorMessage,
    setErrorMessage,
    logIn,
    logOut,
    isLogged,
    isAdmin,
    refreshAuth,
  };
}
