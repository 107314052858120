import { FaHome, FaShoppingBag, FaUsers } from 'react-icons/fa';
// import logo from '../../../images/logo_tachoapp.jpeg';
import TachoLogo from '../../../images/TACHOAPPLOGO.webp';
import SidebarPanel from './SidebarPanel';
import SidebarIconRef from './SidebarIconRef';
import { FaTruck } from 'react-icons/fa';
import { IoIosTime } from 'react-icons/io';
import { GiShop } from 'react-icons/gi';

interface SidepanelProps {
  isSidebarExpanded: boolean;
  toggleSidebar: () => void;
}

export default function Sidebar({
  isSidebarExpanded,
  toggleSidebar,
}: SidepanelProps) {
  return (
    <>
      <div className="main-sidebar">
        <div className="flex h-full w-full flex-col items-center border-r border-slate-150 bg-white dark:border-navy-700 dark:bg-navy-800">
          {/* Application Logo */}
          <a href="/admin/home">
            <img
              className="h-16 transition-transform duration-100 ease-in-out hover:rotate-[45deg] hover:scale-90"
              src={TachoLogo}
              alt="Logo"
            />
          </a>

          {/* Main Sections Links */}
          <div className="is-scrollbar-hidden flex grow flex-col space-y-4 overflow-y-auto">
            {/* Dashboards */}
            <SidebarIconRef href="/admin/home" icon={<FaHome size={24} />} />
            <SidebarIconRef href="/admin/users" icon={<FaUsers size={24} />} />
            <SidebarIconRef
              href="https://mtacho.pl/produkt/rozliczanie-czasu-pracy-kierowcow/"
              icon={<FaTruck size={24} />}
            />
            <SidebarIconRef
              href="https://allegro.pl/uzytkownik/Tachoss"
              icon={<GiShop size={24} />}
            />
            <SidebarIconRef
              href="https://mtacho.pl/sklep/"
              icon={<FaShoppingBag size={24} />}
            />
            <SidebarIconRef
              href="https://mtacho.pl/produkt/sprawdzanie-naruszen-czasu-pracy-itd-4/"
              icon={<IoIosTime size={24} />}
            />
          </div>
        </div>
      </div>
      <div className="sidebar-panel">
        <SidebarPanel
          isSidebarExpanded={isSidebarExpanded}
          toggleSidebar={toggleSidebar}
        />
      </div>
    </>
  );
}
