import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { MdDownloadForOffline } from 'react-icons/md';
import { BarChart, PreviewChart } from '../';

export default function UserStatistics() {
  const [activeTab, setActiveTab] = useState<'tabRecent' | 'tabAll'>(
    'tabRecent'
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleTabClick = (tab: 'tabRecent' | 'tabAll') => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/* Statistics */}
      <div className="col-span-12 lg:col-span-8">
        {/* Header */}
        <div className="flex items-center justify-between space-x-2">
          <h2 className="text-base font-medium tracking-wide text-slate-800 line-clamp-1 dark:text-navy-100">
            Kilometry
          </h2>
          <div className="is-scrollbar-hidden overflow-x-auto rounded-lg bg-slate-200 text-slate-600 dark:bg-navy-800 dark:text-navy-200">
            <div className="tabs-list flex p-1">
              <button
                onClick={() => handleTabClick('tabRecent')}
                className={`btn shrink-0 px-3 py-1 text-xs+ font-medium ${
                  activeTab === 'tabRecent'
                    ? 'bg-white shadow dark:bg-navy-500 dark:text-navy-100'
                    : 'hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'
                }`}>
                Ostatni miesiąc
              </button>
              <button
                onClick={() => handleTabClick('tabAll')}
                className={`btn shrink-0 px-3 py-1 text-xs+ font-medium ${
                  activeTab === 'tabAll'
                    ? 'bg-white shadow dark:bg-navy-500 dark:text-navy-100'
                    : 'hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100'
                }`}>
                Ostatni rok
              </button>
            </div>
          </div>
        </div>
        {/* Charts */}
        <div className="flex flex-col sm:flex-row sm:space-x-7 flex-grow h-full">
          {/* Charts stats */}
          <div className="mt-4 flex-shrink-0 flex flex-col items-center sm:items-start">
            <div className="mt-4">
              <div className="flex items-center space-x-1">
                <p className="text-2xl font-bold text-slate-700 dark:text-navy-100">
                  {activeTab === 'tabRecent' ? '6,000 km' : '40,000 km'}
                </p>
              </div>
            </div>
            <p className="text-xs text-slate-400 dark:text-navy-300">
              {activeTab === 'tabRecent' ? 'Tego miesiąca' : 'Cały rok'}
            </p>

            <div className="mt-3 flex justify-center items-center space-x-7">
              <div className="w-28">
                <PreviewChart activeTab={activeTab} />
              </div>

              <div className="flex items-center space-x-0.5">
                <FaArrowUp className="size-4 text-success " />
                <p className="text-sm+ text-slate-800 dark:text-navy-100">
                  3.2%
                </p>
              </div>
            </div>
            <a href={require('../../../mock/Dane.csv')} download={'Dane'}>
              <button className="btn mt-8 space-x-2 rounded-full border border-slate-300 px-3 text-xs+ font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                <MdDownloadForOffline className="size-4.5 text-slate-400 dark:text-navy-300" />
                <span> Pobierz raport</span>
              </button>
            </a>
          </div>

          {/* Actual chart with data */}
          <div className="ax-transparent-gridline grid w-full grid-cols-1">
            <BarChart activeTab={activeTab} windowWidth={windowWidth} />
          </div>
        </div>
      </div>
    </>
  );
}
