import './App.css';
import { ProvideAuth } from './hooks/useAuth';
import { ProvideNotification } from './context';
import Outlet from './routes/Outlet';

function App() {
  return (
    <ProvideNotification>
      <ProvideAuth>
        <Outlet />
      </ProvideAuth>
    </ProvideNotification>
  );
}

export default App;
