import { useEffect, useState } from 'react';
import apiClient from '../api/base';

const useLastCardDownload = (userId: number) => {
  const [date, setDate] = useState<string>('');

  useEffect(() => {
    const fetchDate = async () => {
      try {
        const response = await apiClient.get(`ddd/last-read/${userId}/`);
        let date = response.data.last_read;
        if (date === null) {
          setDate('Brak danych');
          return;
        }
        let currentDate = new Date(date);
        setDate(currentDate.toLocaleString());
      } catch (e) {
        setDate('Wystąpił błąd podczas pobierania daty');
      }
    };

    if (!date) {
      fetchDate();
    }
  }, [date]);

  return date;
};

export default useLastCardDownload;
