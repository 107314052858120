import Chart from "react-apexcharts";

interface BarChartProps {
  activeTab: string;
  windowWidth: number;
}

export default function BarChart({ activeTab, windowWidth }: BarChartProps) {
  const dataValue1 =
    activeTab === "tabRecent"
      ? [10, 5, 15, 25, 27.5, 20, 18, 22, 28, 30, 35, 40]
      : [15, 10, 20, 30, 32.5, 25, 23, 27, 33, 35, 40, 45];
  const dataValue2 =
    activeTab === "tabRecent"
      ? [8, 4, 12, 20, 22.5, 16, 14, 18, 24, 26, 30, 34]
      : [12, 8, 16, 24, 26.5, 20, 18, 22, 28, 30, 35, 39];
  const months = [
    "Sty",
    "Lut",
    "Mar",
    "Kwi",
    "Maj",
    "Cze",
    "Lip",
    "Sie",
    "Wrz",
    "Paź",
    "Lis",
    "Gru",
  ];

  const calculateBorderRadius = () => {
    if (windowWidth < 833) {
      return 2;
    } else {
      return 5;
    }
  };

  const chartOptions = {
    chart: {
      background: "transparent",
      toolbar: {
        show: false,
      },
      offsetY: 15,
    },
    grid: {
      padding: {
        left: 0,
        bottom: 0,
        top: -15,
      },
    },
    series: [
      {
        name: "serie1",
        data: dataValue1,
      },
      {
        name: "serie2",
        data: dataValue2,
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        borderRadius: calculateBorderRadius(),
      },
    },
    xaxis: {
      categories: months,
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        show: false,
        offsetX: -50,
      },
    },
    legend: {
      show: false,
    },
    colors: ["#6667EB", "#31B1EC"],
  };

  return (
    <Chart
      options={chartOptions}
      series={chartOptions.series}
      type="bar"
      width="100%"
      height="88%"
    />
  );
}
