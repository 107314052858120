import InformationCard from "./InformationCard";
import { FaPeopleGroup, FaTruck, FaBoltLightning } from "react-icons/fa6";
import { FaHandHoldingUsd } from "react-icons/fa";
import { IoIosTime } from "react-icons/io";
import { MdEmojiTransportation } from "react-icons/md";

export default function InformationGrid() {
  return (
    <div className="col-span-12 lg:col-span-4">
      <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-5 lg:grid-cols-2">
        <InformationCard
          value="10"
          title="Kierowców"
          icon={<FaPeopleGroup className="size-6 text-primary" />}
        />
        <InformationCard
          value="22"
          title="Klientów"
          icon={<FaHandHoldingUsd className="size-6 text-error" />}
        />
        <InformationCard
          value="15"
          title="Przewozów"
          icon={<MdEmojiTransportation className="size-6 text-success-focus" />}
        />
        <InformationCard
          value="23"
          title="Pojazdów"
          icon={<FaTruck className="size-6 text-secondary" />}
        />
        <InformationCard
          value="58%"
          title="Efektywność kierowców"
          icon={<FaBoltLightning className="size-6 text-warning" />}
        />
        <InformationCard
          value="3 dni"
          title="Średni czas trwania przewozu"
          icon={<IoIosTime className="size-6 text-info" />}
        />
      </div>
    </div>
  );
}
