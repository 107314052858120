import React from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import useFiles from '../../../hooks/useFiles';
import moment from 'moment';
import Pagination from '../../Utils/Pagination/Pagination';

interface IFile {
  dddfile: string;
  user: number;
  upload_date: string;
  index?: number;
}

interface FilesDialogProps {
  dialogOpen: boolean;
  setDialogOpen: (value: boolean) => void;
  email: string;
}

const FilesDialog: React.FC<FilesDialogProps> = ({
  dialogOpen,
  setDialogOpen,
  email,
}) => {
  const {
    files,
    error,
    next,
    previous,
    goToNextPage,
    goToPreviousPage,
    currentPage,
  } = useFiles(email, dialogOpen);

  return (
    <Dialog open={dialogOpen} onClose={setDialogOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-slate-200 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full w-full items-center justify-center p-4 text-center sm:items-center sm:p-0 mt-[7vh]">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-[90vw] md:w-[40vw] data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="font-semibold leading-6 text-slate-700 text-2xl">
                    Pliki DDD
                  </DialogTitle>
                  <div className="mt-8">
                    {error ? (
                      <p className="text-lg text-red-500">{error}</p>
                    ) : files.length === 0 ? (
                      <p className="text-gray-700 text-lg">Brak plików</p>
                    ) : (
                      <div className="space-y-3">
                        {files.map((file: IFile, index: number) => (
                          <div
                            key={index}
                            className="flex items-start justify-start gap-4">
                            <a
                              href={file.dddfile}
                              download
                              className="btn bg-primary px-3 py-2 text-white font-medium hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                              Pobierz
                            </a>
                            <span className="text-gray-700 text-lg">
                              {`${moment(file.upload_date).format('DD.MM.YYYY')}/${file.index}`}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 pt-6 pb-3 flex flex-row-reverse items-center justify-center sm:px-6 gap-4">
              <button
                type="button"
                data-autofocus
                onClick={() => setDialogOpen(false)}
                className="btn space-x-2 bg-primary px-4 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                Zamknij
              </button>
              <Pagination
                currentPage={currentPage}
                goToPreviousPage={goToPreviousPage}
                goToNextPage={goToNextPage}
                prvPage={previous}
                nextPage={next}
              />
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default FilesDialog;
