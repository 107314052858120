import React from 'react';
import { Navigation } from '../../../components';
import { useLocation } from 'react-router-dom';
import { UserProfile } from '../../../components/Admin';
import { UserNotFound } from '../../../components/Utils';

export default function UserProfilePage() {
  const location = useLocation();
  const userData = location.state || {};

  return (
    <>
      <Navigation />
      <div className="flex items-center space-x-4 pt-5 lg:pt-6">
        <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
          Profil
        </h2>
      </div>
      {userData.id ? <UserProfile {...userData} /> : <UserNotFound />}
    </>
  );
}
