import { Tooltip } from 'react-tooltip';

const ToolipStyles = {
  padding: '6px',
  borderRadius: '15%',
  fontWeight: 'bold',
};

export default function GoalStatus() {
  return (
    <>
      <div className="col-span-12 lg:col-span-4">
        <div className="flex items-center justify-between pt-2">
          <h2 className="font-medium tracking-wide text-slate-700 dark:text-navy-100">
            Zadowolenie klientów
          </h2>
        </div>
        <div className="mt-3">
          <p>
            <span className="text-3xl text-slate-700 dark:text-navy-100">
              9.7
            </span>
            <span className="text-xs text-success">+2.1%</span>
          </p>
          <p className="text-xs+">Wydajność</p>
        </div>
        {/* Lines */}
        <div className="mt-4 flex h-2 space-x-1">
          <div
            className="w-5/12 rounded-full bg-primary dark:bg-accent"
            data-tooltip-id="verygood"
            data-tooltip-content="Świetnie"
            data-tooltip-place="top">
            <Tooltip
              id="verygood"
              style={{
                backgroundColor: '#4f46e5',
                ...ToolipStyles,
              }}
            />
          </div>
          <div
            className="w-2/12 rounded-full bg-success"
            data-tooltip-id="success"
            data-tooltip-content="Bardzo dobrze"
            data-tooltip-place="top">
            <Tooltip
              id="success"
              style={{ backgroundColor: '#10b981', ...ToolipStyles }}
            />
          </div>
          <div
            className="w-2/12 rounded-full bg-info"
            data-tooltip-id="info"
            data-tooltip-content="Dobrze"
            data-tooltip-place="top">
            <Tooltip
              id="info"
              style={{ backgroundColor: '#0ea5e9', ...ToolipStyles }}
            />
          </div>
          <div
            className="w-2/12 rounded-full bg-warning"
            data-tooltip-id="warning"
            data-tooltip-content="Słabo"
            data-tooltip-place="top">
            <Tooltip
              id="warning"
              style={{ backgroundColor: '#ff9800', ...ToolipStyles }}
            />
          </div>
          <div
            className="w-1/12 rounded-full bg-error"
            data-tooltip-id="error"
            data-tooltip-content="Bardzo słabo"
            data-tooltip-place="top">
            <Tooltip
              id="error"
              style={{ backgroundColor: '#ff5724', ...ToolipStyles }}
            />
          </div>
        </div>
        {/* History */}
        <div className="is-scrollbar-hidden mt-4 min-w-full overflow-x-auto">
          <table className="w-full font-inter">
            <tbody>
              <tr>
                <td className="whitespace-nowrap py-2">
                  <div className="flex items-center space-x-2">
                    <div className="size-3.5 rounded-full border-2 border-primary dark:border-accent"></div>
                    <p className="font-medium tracking-wide text-slate-700 dark:text-navy-100">
                      Świetnie
                    </p>
                  </div>
                </td>
                <td className="whitespace-nowrap py-2 text-right">
                  <p className="font-medium text-slate-700 dark:text-navy-100">
                    1 029
                  </p>
                </td>
                <td className="whitespace-nowrap py-2 text-right">42%</td>
              </tr>
              <tr>
                <td className="whitespace-nowrap py-2">
                  <div className="flex items-center space-x-2">
                    <div className="size-3.5 rounded-full border-2 border-success"></div>
                    <p className="font-medium tracking-wide text-slate-700 dark:text-navy-100">
                      Bardzo dobrze
                    </p>
                  </div>
                </td>
                <td className="whitespace-nowrap py-2 text-right">
                  <p className="font-medium text-slate-700 dark:text-navy-100">
                    426
                  </p>
                </td>
                <td className="whitespace-nowrap py-2 text-right">18%</td>
              </tr>
              <tr>
                <td className="whitespace-nowrap py-2">
                  <div className="flex items-center space-x-2">
                    <div className="size-3.5 rounded-full border-2 border-info"></div>
                    <p className="font-medium tracking-wide text-slate-700 dark:text-navy-100">
                      Dobrze
                    </p>
                  </div>
                </td>
                <td className="whitespace-nowrap py-2 text-right">
                  <p className="font-medium text-slate-700 dark:text-navy-100">
                    326
                  </p>
                </td>
                <td className="whitespace-nowrap py-2 text-right">14%</td>
              </tr>
              <tr>
                <td className="whitespace-nowrap py-2">
                  <div className="flex items-center space-x-2">
                    <div className="size-3.5 rounded-full border-2 border-warning"></div>
                    <p className="font-medium tracking-wide text-slate-700 dark:text-navy-100">
                      Słabo
                    </p>
                  </div>
                </td>
                <td className="whitespace-nowrap py-2 text-right">
                  <p className="font-medium text-slate-700 dark:text-navy-100">
                    395
                  </p>
                </td>
                <td className="whitespace-nowrap py-2 text-right">17%</td>
              </tr>
              <tr>
                <td className="whitespace-nowrap py-2">
                  <div className="flex items-center space-x-2">
                    <div className="size-3.5 rounded-full border-2 border-error"></div>
                    <p className="font-medium tracking-wide text-slate-700 dark:text-navy-100">
                      Bardzo słabo
                    </p>
                  </div>
                </td>
                <td className="whitespace-nowrap py-2 text-right">
                  <p className="font-medium text-slate-700 dark:text-navy-100">
                    129
                  </p>
                </td>
                <td className="whitespace-nowrap py-2 text-right">9%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
