interface InformationCardProps {
  value: string;
  title: string;
  icon: React.ReactNode;
}

export default function InformationCard({
  value,
  title,
  icon,
}: InformationCardProps) {
  return (
    <>
      <div className="rounded-lg bg-slate-150 p-4 dark:bg-navy-700">
        <div className="flex justify-between space-x-1">
          <p className="text-xl font-bold text-slate-700 dark:text-navy-100">
            {value}
          </p>
          {icon}
        </div>
        <p className="mt-1 text-xs+">{title}</p>
      </div>
    </>
  );
}
