import DeliveryCardStatus from "./DeliveryStatusCard";

export default function DeliveryStatus() {
  return (
    <div className="card col-span-12 lg:col-span-8">
      <div className="flex items-center justify-between py-3 px-4">
        <h2 className="font-medium tracking-wide text-slate-700 dark:text-navy-100">
          Status dowozu
        </h2>
      </div>
      <div className="grid grid-cols-1 gap-y-4 pb-3 sm:grid-cols-3">
        <DeliveryCardStatus
          avatarImg="https://www.fusionphotopro.com/wp-content/uploads/2016/06/heasdshot-contemporary-mature-man-blues-705x524.jpg"
          boderColorClass="border-l-info"
          from="Lodz"
          to="Berlin"
          driverName="Michal Turek"
          date="15 czerwca 2024"
          place="Lodz"
        />
        <DeliveryCardStatus
          avatarImg="https://www.fusionphotopro.com/wp-content/uploads/2016/06/heasdshot-contemporary-mature-man-blues-705x524.jpg"
          boderColorClass="border-l-secondary"
          from="Kutno"
          to="Porto"
          driverName="Michal Turek"
          date="16 czerwca 2024"
          place="Kutno"
        />
        <DeliveryCardStatus
          avatarImg="https://www.fusionphotopro.com/wp-content/uploads/2016/06/heasdshot-contemporary-mature-man-blues-705x524.jpg"
          boderColorClass="border-l-warning"
          from="Lodz"
          to="Monachium"
          driverName="Michal Turek"
          date="17 czerwca 2024"
          place="Nowy sacz"
        />
      </div>
    </div>
  );
}
