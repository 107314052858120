import axios from 'axios';

const apiClient = axios.create({
  withCredentials: true,
  baseURL: 'https://tacho.internetstars.pl/api/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // Sprawdź, czy status to 401 i czy żądanie nie było już oznaczone do ponowienia
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const isRefreshRequest = originalRequest.url.includes('auth/refresh/');

      if (!isRefreshRequest) {
        try {
          await apiClient.post('auth/refresh/');

          return apiClient(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      } else {
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
