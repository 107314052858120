import { useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { Navigation, UserCard, Pagination } from '../../../components';
import { useNavigate } from 'react-router-dom';
import useUsers from '../../../hooks/useUsers';

export default function UsersDashboard() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [localUsers, setLocalUsers] = useState<any[]>([]);

  const {
    getUserList,
    getParticularUserByFullName,
    currentPage,
    prvPage,
    nextPage,
    goToNextPage,
    goToPreviousPage,
    allUsers,
  } = useUsers();

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    setLocalUsers(allUsers);
  }, [allUsers]);

  const handleChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearchTerm(value);

    if (value.trim() === '') {
      getUserList();
    } else if (value.length % 2 === 0) {
      await getParticularUserByFullName(value);
    } else {
      const users = localUsers.filter(
        user =>
          user.first_name.toLowerCase().includes(value.toLowerCase()) ||
          user.last_name.toLowerCase().includes(value.toLowerCase())
      );
      setLocalUsers(users);
    }
  };

  const goToUserForm = () => {
    navigate('/admin/users/create');
  };

  return (
    <>
      <Navigation />
      <div className="flex items-center justify-between py-4">
        <h2 className="text-xl font-medium text-slate-700 line-clamp-1 dark:text-navy-50 lg:text-2xl">
          Użytkownicy
        </h2>
        <div className="flex items-center gap-4">
          <label className="relative hidden sm:flex">
            <input
              className="form-input peer h-9 w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 text-xs+ placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
              placeholder="Szukaj użytkowników"
              type="text"
              value={searchTerm}
              onChange={handleChange}
            />
            <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
              <CiSearch />
            </span>
          </label>
          <Pagination
            currentPage={currentPage}
            goToPreviousPage={goToPreviousPage}
            goToNextPage={goToNextPage}
            prvPage={prvPage}
            nextPage={nextPage}
          />
        </div>
      </div>
      <button
        className="btn w-fit mb-8 bg-primary px-4 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
        onClick={goToUserForm}>
        <span>Stwórz nowego użytkownika</span>
      </button>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4 pb-12">
        {localUsers?.map(user => <UserCard key={user.id} {...user} />)}
      </div>
    </>
  );
}
